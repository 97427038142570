<template>
  <li class="relative pb-10 ml-2.5">
    <div
      v-if="!isLast"
      class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-400"
      aria-hidden="true"
    ></div>
    <!-- Complete Step -->
    <div class="relative flex items-start group">
      <!-- done -->
      <span v-if="status === VALIDATION_STATE.SUCCESS" class="h-9 flex items-center">
        <span
          class="relative z-10 w-8 h-8 flex items-center justify-center bg-green-600 rounded-full"
        >
          <!-- Heroicon name: solid/check -->
          <svg
            class="w-5 h-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </span>

      <span
        v-else-if="status === VALIDATION_STATE.PENDING"
        class="h-9 flex items-center"
        aria-hidden="true"
      >
        <span
          class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full"
        >
          <span class="flex h-3 w-3">
            <span
              class="animate-ping absolute inline-flex inset-0 h-full w-full rounded-full bg-indigo-400 opacity-75"
            ></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-indigo-500"></span>
          </span>
        </span>
      </span>

      <span
        v-else-if="status === VALIDATION_STATE.NOT_STARTED"
        class="h-9 flex items-center"
        aria-hidden="true"
      >
        <span
          class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full"
        >
          <span class="flex h-3 w-3">
            <span class="relative inline-flex rounded-full h-3 w-3 bg-gray-500"></span>
          </span>
        </span>
      </span>

      <span v-if="status === VALIDATION_STATE.ERROR" class="h-9 flex items-center">
        <span
          class="relative z-10 w-8 h-8 flex items-center justify-center bg-red-600 rounded-full"
        >
          <icon-x class="w-5 h-5 text-white"></icon-x>
        </span>
      </span>

      <router-link v-if="route" :to="{name: route}">
        <span class="ml-4 min-w-0 flex flex-col">
          <span class="text-xs font-semibold tracking-wide uppercase">
            <span>{{title}}</span>

            <icon-arrow-narrow-right
              v-if="route"
              class="w-3 h-3 text-gray-700 ml-1.5 mb-0.5 inline-block transform -rotate-45"
            ></icon-arrow-narrow-right>
          </span>
          <span class="text-sm text-gray-500">{{subtitle}}</span>
        </span>
      </router-link>

      <span v-else class="ml-4 min-w-0 flex flex-col">
        <span class="text-xs font-semibold tracking-wide uppercase">
          <span>{{title}}</span>

          <icon-arrow-narrow-right
            v-if="route"
            class="w-3 h-3 text-gray-700 ml-1.5 mb-0.5 inline-block transform -rotate-45"
          ></icon-arrow-narrow-right>
        </span>
        <span class="text-sm text-gray-500">{{subtitle}}</span>
      </span>
    </div>
  </li>
</template>

<script>
import VALIDATION_STATE from '@/util/validationState';

export default {
  name: 'PlanningValidationStep',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: VALIDATION_STATE.PENDING,
    },
  },
  data() {
    return {
      VALIDATION_STATE,
    };
  },
};
</script>
