export default [
  { label: 'Mandag', value: 'MONDAY' },
  { label: 'Tirsdag', value: 'TUESDAY' },
  { label: 'Onsdag', value: 'WEDNESDAY' },
  { label: 'Torsdag', value: 'THURSDAY' },
  { label: 'Fredag', value: 'FRIDAY' },
  { label: 'Lørdag', value: 'SATURDAY' },
  { label: 'Søndag', value: 'SUNDAY' },
  { label: 'Ukendt', value: 'UNKNOWN' },
];
