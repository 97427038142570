import * as disposalDays from './disposalDays';
import plannedWeekNo from './plannedWeekNo';
import routeGroup from './routeGroup';
import taskTranslations from './taskTranslations';
import planningActor from './planningActor';
import propertyZone from './propertyZone';

const daLocale = {
  disposalDays: disposalDays.disposalDays,
  disposalDayList: disposalDays.disposalDayList,
  plannedWeekNo,
  routeGroup,
  taskFields: taskTranslations,
  planningActor,
  propertyZone,
};

export default daLocale;
