<template>
  <secondary-nav-bar-content
    title="Ændringslister"
    description="Her kan du generere ændringslister og efterfølgende downloade dem som et Excel-ark. Ændringslister giver typisk kun mening, hvis din planlægning har taget udgangspunkt i eksisterende ruter"
  >
    <base-button
      slot="actions"
      color="indigo"
      :disabled="isCreateDisabled"
      @click="onGenerateChangeList"
    >Opret ny</base-button>

    <base-alert
      v-if="hasUnplannedTasks"
      type="warning"
      title="Du har uplanlagte opgaver"
      class="mt-4"
      description="Hvis du vil oprette ændringslister nye, skal du planlægge alle opgaver først."
    ></base-alert>

    <job-poller
      v-if="generateChangeListJobId"
      title="Genererer ændringsliste"
      description="Dette kan tage nogle minutter, hvis der er mange opgaver"
      :job-id="generateChangeListJobId"
      :type="jobTypes.GENERATECHANGELIST"
      @result="onGenerateResult"
    ></job-poller>

    <base-card v-if="!changeLists.length && !hasUnplannedTasks" class="mt-4">
      <image-container asset-name="empty" title="Ingen ændringslister">
        <base-button
          color="indigo"
          class="mt-6"
          :disabled="isCreateDisabled"
          @click="onGenerateChangeList"
        >Opret ændringsliste</base-button>
      </image-container>
    </base-card>

    <div class="relative mt-6">
      <ul v-loading="changeListsLoading" class="space-y-4">
        <change-list-item
          v-for="cv in changeLists"
          :key="cv.id"
          :item="cv"
          @on-download="downloadExcel"
        ></change-list-item>
      </ul>
    </div>
  </secondary-nav-bar-content>
</template>

<script>
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import ImageContainer from '@/components/ImageContainer.vue';
import ChangeListItem from '@/components/ChangeListItem.vue';
import ChangeListsService from '@/services/changeLists.service';

import { mapGetters, mapActions } from 'vuex';

import { GENERATE_CHANGE_LIST, GET_CHANGE_LISTS } from '@/store/actions.type';

import * as jobTypes from '@/util/jobTypes';
import JobPoller from '@/components/JobPoller.vue';

export default {
  name: 'DrivingListsView',
  components: {
    SecondaryNavBarContent,
    ImageContainer,
    ChangeListItem,
    JobPoller,
  },
  data() {
    return {
      jobTypes,
      isDoneGenerating: false,
    };
  },
  computed: {
    ...mapGetters('jobs', ['generateChangeListJobId']),
    ...mapGetters('status', ['hasUnplannedTasks']),
    ...mapGetters('changelists', ['changeLists', 'changeListsLoading']),
    isCreateDisabled() {
      if (this.hasUnplannedTasks) return true;

      if (this.isDoneGenerating) return false;
      return !!this.generateChangeListJobId || !!this.changeListsLoading;
    },
  },
  async mounted() {
    await this.fetchAll();
  },
  methods: {
    ...mapActions('changelists', {
      getAll: GET_CHANGE_LISTS,
      generateChangeList: GENERATE_CHANGE_LIST,
    }),
    async fetchAll() {
      try {
        this.getAll();
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async onGenerateChangeList() {
      this.isDoneGenerating = false;
      try {
        this.$ntf.info('Starter generering af ændringsliste..');
        await this.generateChangeList();
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    async onGenerateResult() {
      this.isDoneGenerating = true;
      await this.fetchAll();
    },
    async downloadExcel(id) {
      this.$ntf.info('Henter ark');
      await ChangeListsService.downloadExcel(id);
    },
  },
};
</script>
