<template>
  <div class="w-full pb-24">
    <image-container
      v-if="!hasUnplannedTasks"
      asset-name="success"
      title="Du har ingen uplanlagte opgaver"
    ></image-container>

    <div v-else class="flex-1 min-w-0 xl:flex mt-4">
      <!-- Validation sidebar -->
      <div class="flex-shrink-0 w-72 mr-12">
        <from-scratch-validation-area
          class="mt-4"
          @on-is-valid-change="isEveryStepValidated = $event"
        ></from-scratch-validation-area>
      </div>
      <job-poller
        v-if="buildRoutesFromScratchAutomaticJobId"
        title="Bygger ruter fra bunden"
        :has-post-process="true"
        description="Dette kan tage nogle minutter, hvis der er mange ruter"
        :job-id="buildRoutesFromScratchAutomaticJobId"
        :type="jobTypes.BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC"
        @result="onBuildRoutesFromScratchResult"
      ></job-poller>
      <div v-else class="rounded-md min-w-0 flex-1">
        <div
          class="pl-4 pr-6 pt-4 pb-4 border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0"
        >
          <base-alert type="info" title="Køretøjer" class="mb-4">
            <p>
              Alle køretøjer markeret som 'Aktiv' vil indgå. 'Ukendt' køretøj indgår ikke i beregningen.
              <br />Ønsker du at ændre opsætningen gøres dette under
              <router-link class="underline text-indigo-600" :to="{name: 'vehicles'}">køretøjer</router-link>
            </p>
          </base-alert>

          <div v-if="isEveryStepValidated">
            <image-container asset-name="building_blocks">
              <base-button
                color="indigo"
                class="mt-6"
                :disabled="buildRoutesFromScratchAutomaticJobId"
                @click="onBuildRoutesSubmit"
              >Byg ruter fra bunden automatisk</base-button>
            </image-container>

            <div v-if="buildRoutesError">
              <image-container
                asset-name="error"
                title="Der skete en valideringsfejl"
                :subtitle="buildRoutesError.error"
              ></image-container>

              <ul>
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-2">
                    <dt class="text-sm font-medium text-gray-500">Opgaver med manglende frekvenser</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span
                        class="text-gray-500 font-bold"
                      >{{buildRoutesError.missing_frequency_mappings}}</span>
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-2">
                    <dt class="text-sm font-medium text-gray-500">Opgaver med manglende geokodninger</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="text-gray-500 font-bold">{{buildRoutesError.non_geocoded}}</span>
                    </dd>
                  </div>
                </dl>
              </ul>
            </div>
          </div>

          <base-alert
            v-else
            type="warning"
            title="Dit datasæt er ikke klar til at blive planlagt"
            description="Tryk på punkterne i sidemenuen til venstre for at gøre dit datasæt klar"
          ></base-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ImageContainer from '@/components/ImageContainer.vue';
import FromScratchValidationArea from '@/components/build-routes/FromScratchValidationArea.vue';

import { BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC, CHECK_PLANNED_STATUS } from '@/store/actions.type';
import * as jobTypes from '@/util/jobTypes';
import JobPoller from '@/components/JobPoller.vue';

export default {
  name: 'PlanScratchAutomaticView',
  components: {
    ImageContainer,
    JobPoller,
    FromScratchValidationArea,
  },
  data() {
    return {
      buildRoutesError: null,
      isEveryStepValidated: false,
      jobTypes,
    };
  },
  computed: {
    ...mapGetters('jobs', ['buildRoutesFromScratchAutomaticJobId']),
    ...mapGetters('status', ['hasPlanned', 'hasUnplannedTasks']),
    ...mapGetters('departments', ['activeWorkspace']),
  },
  methods: {
    ...mapActions('planning', {
      buildRoutesFromScratchAutomatic: BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC,
    }),
    ...mapActions('status', {
      checkPlannedStatus: CHECK_PLANNED_STATUS,
    }),
    onBuildRoutesFromScratchResult() {
      this.checkPlannedStatus();
      this.$router.push({ name: 'map' });
    },
    async onBuildRoutesSubmit() {
      try {
        this.$ntf.info('Bygger ruter automatisk');
        await this.buildRoutesFromScratchAutomatic();
      } catch (error) {
        if (error.status === 500) {
          this.$unhandledError(error);
        } else {
          this.buildRoutesError = error.data;
        }
      }
    },
  },
};
</script>
