<template>
  <div class="p-4 flex flex-col items-center justify-center">
    <span v-if="activeStatus === jobStatus.SUCCEEDED" class="flex h-10 w-10 relative flex-center">
      <div class="bg-green-500 h-8 w-8 flex-center rounded-full">
        <icon-check class="h-6 w-6 text-green-100"></icon-check>
      </div>
    </span>
    <span v-else-if="activeStatus === jobStatus.FAILED" class="flex h-10 w-10 relative flex-center">
      <div class="bg-red-600 h-8 w-8 flex-center rounded-full">
        <icon-x class="h-6 w-6 text-red-100"></icon-x>
      </div>
    </span>
    <span v-else class="flex h-10 w-10 flex-shrink-0 rounded-full bg-white relative flex-center">
      <span class="rounded-full h-8 w-8 bg-gray-300"></span>
    </span>

    <p class="text-gray-800 font-semibold mt-4">{{title}}</p>
  </div>
</template>

<script>
import * as jobStatus from '@/util/jobStatus';

export default {
  name: 'JobPollerDot',
  props: {
    activeStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      jobStatus,
    };
  },
  computed: {
    title() {
      let title = '';
      switch (this.activeStatus) {
        case jobStatus.SUCCEEDED:
          title = 'Success';
          break;
        case jobStatus.FAILED:
          title = 'Fejlet';
          break;

        default:
          title = 'Resultat';
          break;
      }

      return title;
    },
  },
};
</script>
