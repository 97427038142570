const translations = {
  MONDAY: 'Man',
  TUESDAY: 'Tir',
  WEDNESDAY: 'Ons',
  THURSDAY: 'Tor',
  FRIDAY: 'Fre',
  SATURDAY: 'Lør',
  SUNDAY: 'Søn',
  UNKNOWN: 'Ukendt',
};

const disposalDays = (value) => {
  if (!value) return '';

  return translations[value];
};

const disposalDayList = (value) => {
  if (!value) return '';

  return value.map((day) => translations[day]).join(', ');
};

export { disposalDays, disposalDayList };
