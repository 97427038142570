<template>
  <div
    class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-start space-x-3"
  >
    <div class="flex-shrink-0">
      <div class="h-12 w-12 rounded-full flex-center bg-gray-100">
        <icon-document-text class="h-6 w-6 text-gray-600"></icon-document-text>
      </div>
    </div>
    <div class="flex-1 flex flex-col justify-between min-w-0">
      <span class="text-gray-500">Oprettet</span>
      <p class="text-lg font-medium text-gray-700">{{item.createdAt | longDateTime}}</p>
    </div>

    <div class="flex space-x-16">
      <div class="h-full flex justify-center items-start flex-col w-32">
        <base-button class="w-full" @click="onDownload(item.id)">
          <span>Download</span>
          <span class="font-bold ml-2 mt-0.5 text-indigo-600" style="font-size: 10px;">.Excel</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onDownload(id) {
      this.$emit('on-download', id);
    },
  },
};
</script>
