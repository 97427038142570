<template>
  <div class="grid sm:grid-cols-2 grid-cols-1 gap-8">
    <div class="mt-6">
      <h3 class="sub-headline">Manuelt</h3>

      <ul role="list" class="py-6 space-y-12">
        <planning-option-card
          color="indigo"
          title="Byg eksisterende ruter"
          description="Generer eksisterende ruter ud fra datasættet."
          route-name="existing"
        >
          <icon-library class="h-6 w-6 text-white"></icon-library>
        </planning-option-card>

        <planning-option-card
          color="blue"
          title="Planlæg selv helt fra bunden"
          description="Forbered planlægning fra bunden ved at sætte alle opgaver på Ukendt køretøj"
          route-name="scratch"
        >
          <icon-lightning-bolt class="w-6 h-6 text-white"></icon-lightning-bolt>
        </planning-option-card>
      </ul>
    </div>

    <div class="mt-6">
      <h3 class="sub-headline">Automatisk</h3>

      <ul role="list" class="py-6 space-y-12">
        <planning-option-card
          color="purple"
          :is-disabled="true"
          title="Justér antal køretøjer"
          description="Hvis du ønsker at se, hvordan workspacet vil se ud med færre- eller flere køretøjer end i dag."
          route-name="plan"
        >
          <icon-light-bulb class="w-6 h-6 text-white"></icon-light-bulb>
        </planning-option-card>

        <planning-option-card
          color="yellow"
          :is-disabled="true"
          title="Planlæg resterende opgaver"
          description="Hvis du har importeret ekstra opgaver i et workspace, kan du automatisk fordele dem med NorthArcs algortime."
          route-name="plan"
        >
          <icon-light-bulb class="w-6 h-6 text-white"></icon-light-bulb>
        </planning-option-card>

        <planning-option-card
          color="green"
          title="Planlæg automatisk fra bunden"
          description="Planlæg helt fra bunden med NorthArcs algortime."
          route-name="scratch-automatic"
        >
          <icon-light-bulb class="w-6 h-6 text-white"></icon-light-bulb>
        </planning-option-card>
      </ul>
    </div>
  </div>
</template>

<script>
import PlanningOptionCard from '@/components/PlanningOptionCard';

export default {
  name: 'PlanPathsView',
  components: {
    PlanningOptionCard,
  },
};
</script>
