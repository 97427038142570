const translations = {
  planned_disposal_days: 'Tømningsdag',
  planned_week_no: 'Uge',
  frequency: 'Frekvens',
  vehicle: 'Køretøj',
  ext_materiel_id: 'Materiel Id',
  materiel: 'Materiel',
  address: 'Adresse',
  address_link: 'Adresse Link',
  zip_code: 'Postnr',
  address_door_letter: 'Bogstav',
  address_number: 'Nr',
  address_road: 'Vej',
  city: 'By',
  road_code: 'Vejkode',
  fraction: 'Fraktion',
  longitude: 'Longitude',
  latitude: 'Latitude',
  quantity: 'Antal',
  container_no: 'ContainerNr',
  container_location: 'Placering',
  comment: 'Bemærkning',
  property_zone: 'Ejendomsstatus',
  property_no: 'EjendomsNr',
  property_reservations: 'EjendomsForhold',
  old_frequency: 'Gammel frekvens',
  old_vehicle: 'Gammel køretøj',
  old_disposal_day: 'Gammel tømmedag',
  old_disposal_days: 'Gamle tømmedage',
  old_week: 'Gammel uge',
  old_week_no: 'Gammel uge',
  planning_actor: 'Aktør',
  imported_frequency: 'Indlæst Frekvens',
  imported_materiel: 'Indlæst Materiel',
  imported_fraction: 'Indlæst Fraktion',
  imported_waste_fraction: 'Indlæst Affaldstype',
  imported_week_no: 'Indlæst Uge',
  imported_property_zone: 'Indlæst ejendomsstatus',
  first_disposal_date: 'Første tømn. dag',
  last_disposal_date: 'Sidste tømn. dag',
  shift: 'Forskydning',
  only_summer: 'Kun sommer',
  csv_export_filename: 'CSV filnavn',
  csv_export_row: 'CSV række',
};

const translate = (value) => {
  if (!value) return '';

  return translations[value];
};
export default translate;
