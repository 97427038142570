<template>
  <li class="flow-root">
    <div
      class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2"
      :class="focusColor"
    >
      <div
        class="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
        :class="bgColor"
      >
        <slot />
      </div>
      <div>
        <h3 class="text-sm font-medium text-gray-900">
          <router-link :to="{name: routeName}" :disabled="isDisabled" class="focus:outline-none">
            <span class="absolute inset-0" aria-hidden="true"></span>
            {{title}}
            <span aria-hidden="true">&rarr;</span>
            <span v-if="isDisabled" class="text-red-700 font-semibold ml-4">(Kommer snart)</span>
          </router-link>
        </h3>
        <p class="mt-1 text-sm text-gray-500">{{description}}</p>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'PlanningOptionCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'indigo',
    },
  },
  computed: {
    bgColor() {
      return {
        indigo: 'bg-indigo-500',
        blue: 'bg-blue-500',
        green: 'bg-green-500',
        yellow: 'bg-yellow-500',
        purple: 'bg-purple-500',
      }[this.color];
    },
    focusColor() {
      return {
        indigo: 'focus-within:ring-indigo-500',
        blue: 'focus-within:ring-blue-500',
        green: 'focus-within:ring-green-500',
        yellow: 'focus-within:ring-yellow-500',
        purple: 'focus-within:ring-purple-500',
      }[this.color];
    },
  },
};
</script>

<style>

</style>
