<template>
  <div>
    <image-container asset-name="success" title="Alle opgaver er planlagt"></image-container>
  </div>
</template>

<script>
import ImageContainer from '@/components/ImageContainer.vue';

export default {
  name: 'AllPlanned',
  components: {
    ImageContainer,
  },
};
</script>

<style>

</style>
