<template>
  <div class="w-full border relative flex flex-col bg-white text-center rounded-md px-4 pt-6 mt-4">
    <h3 class="font-semibold text-gray-800 text-lg">{{title}}</h3>
    <span v-if="description" class="mt-2 text-gray-500">{{description}}</span>
    <button
      v-if="isDone && dismissable"
      class="absolute right-4 top-4 h-8 w-8 flex-center rounded-full focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      @click="closeMenu"
    >
      <icon-x class="text-gray-500 h-6 w-6"></icon-x>
    </button>

    <div class="flex flex-col items-center justify-center">
      <div class="flex justify-center items-center my-6 space-x-10">
        <job-poller-dot
          :active-status="status"
          :status="jobStatus.NOTSTARTED"
          title="Afventer"
          :is-done="status !== jobStatus.NOTSTARTED"
        ></job-poller-dot>
        <job-poller-dot
          :active-status="status"
          :status="jobStatus.INPROGRESS"
          title="Udfører"
          :is-done="isDone || status == jobStatus.POSTPROCESS"
        ></job-poller-dot>
        <job-poller-dot
          v-if="hasPostProcess"
          :active-status="status"
          :status="jobStatus.POSTPROCESS"
          title="Behandler resultat"
          :is-done="isDone && hasPostProcess"
        ></job-poller-dot>
        <job-poller-dot-result :active-status="status"></job-poller-dot-result>
      </div>

      <div v-if="status === jobStatus.FAILED" class="text-gray-600 pb-4">
        <p>Northarc-teamet har modtaget en alarm om din fejl.</p>
        <p>
          Du kan oplyse os dette ID:
          <span class="font-bold">{{jobId}}</span>
        </p>
        <span>- hvis du ønsker hurtigt svar</span>
      </div>
      <div v-if="status === jobStatus.FAILED && errorObj" class="my-8 w-full max-w-md">
        <div class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="px-2 bg-white text-sm flex font-semibold text-gray-800">
              <icon-exclamation class="h-6 w-6 mr-2"></icon-exclamation>
              <span>Valideringsfejl:</span>
            </span>
          </div>
        </div>

        <ul class="space-y-1 mt-2 text-sm text-left list-none text-gray-600">
          <li v-for="(errorKey) in Object.keys(errorObj)" :key="errorKey">
            <span class="font-semibold flex items-center">
              <div class="bg-red-600 h-4 w-4 mr-2 flex-center rounded-full flex-shrink-0">
                <icon-x class="h-3 w-3 text-red-100"></icon-x>
              </div>
              <span v-if="errorKey != 'error'">{{errorKey}}:</span>
              <span class="text-normal ml-1.5">{{errorObj[errorKey]}}</span>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="progressMsg && status !== jobStatus.FAILED && status !== jobStatus.SUCCEEDED"
      class="my-6 relative w-full"
    >
      <div class="flex-center">
        <div
          class="px-4 py-2 text-xs flex-center font-medium leading-none text-center text-green-800 bg-green-200 rounded-full"
        >
          <icon-clock class="w-4 h-4 mr-2 animate-pulse"></icon-clock>
          {{progressMsg}}...
        </div>
      </div>
    </div>

    <slot />

    <span
      v-if="isDev"
      class="cursor-pointer text-xs bg-yellow-200 text-yellow-800 px-4 py-2 mb-4 rounded-md flex-center"
      @click="copyJobId"
    >
      <span>{{jobId}}</span>
      <icon-clipboard class="h-4 w-4 ml-2"></icon-clipboard>
    </span>
  </div>
</template>

<script>
import JobService from '@/services/job.service';

import { mapMutations } from 'vuex';
import { SET_JOB_ID_BY_TYPE } from '@/store/mutations.type';

import JobPollerDot from '@/components/JobPollerDot';
import JobPollerDotResult from '@/components/JobPollerDotResult';

import * as jobStatus from '@/util/jobStatus';

export default {
  name: 'JobPoller',
  components: {
    JobPollerDot,
    JobPollerDotResult,
  },
  props: {
    jobId: {
      type: String,
      default: '',
      required: false,
    },
    type: {
      type: String,
      description: 'The job enum type. Value can be selected in util/jobTypes',
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    dismissable: {
      type: Boolean,
      default: true,
      required: false,
    },
    hasPostProcess: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      status: jobStatus.NOTSTARTED,
      interval: null,
      jobStatus,
      errorObj: null,
      progressMsg: '',
      isDev: process.env.NODE_ENV !== 'production',
    };
  },
  computed: {
    isDone() {
      return [jobStatus.FAILED, jobStatus.SUCCEEDED].includes(this.status);
    },
  },
  watch: {
    jobId: {
      handler: 'setupPollingOfNewJob',
      immediate: true,
    },
  },
  async mounted() {
    this.$once('hook:beforeDestroy', () => {
      this.stop();

      // if the user has already seen the success/failure of this page then we can clear it for the next time
      if ([jobStatus.FAILED, jobStatus.SUCCEEDED].includes(this.status)) {
        this.closeMenu();
      }
    });
  },
  methods: {
    ...mapMutations('jobs', {
      clearJobId: SET_JOB_ID_BY_TYPE,
    }),
    copyJobId() {
      navigator.clipboard.writeText(this.jobId);
      this.$ntf.success('Copied jobId');
    },
    closeMenu() {
      this.clearJobId({
        type: this.type,
        jobId: null,
      });
      this.$emit('closed');
    },
    async setupPollingOfNewJob() {
      if (this.jobId) {
        this.interval = setInterval(this.pollJob, 5000);
        await this.pollJob();
      }
    },
    async pollJob() {
      try {
        const { data } = await JobService.poll(this.jobId);

        const { status, progress_message, related_ids } = data;

        this.progressMsg = progress_message;
        this.status = status;
        this.$emit('update-related-ids', related_ids);

        if (status === jobStatus.SUCCEEDED) {
          this.$emit('result', data.result);
          this.stop();
        } else if (status === jobStatus.FAILED) {
          if (data.error) {
            this.errorObj = data.error;
          }
          this.stop();
          this.$emit('error');
        }
      } catch (error) {
        this.status = jobStatus.FAILED;
        this.stop();
        this.$unhandledError(error);
      }
    },
    stop() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
  },
};
</script>
