<template>
  <div class="p-4 flex flex-col items-center justify-center">
    <span v-if="isDone" class="flex h-10 w-10 relative flex-center">
      <div class="bg-green-500 h-8 w-8 flex-center rounded-full">
        <icon-check class="h-6 w-6 text-green-100"></icon-check>
      </div>
    </span>
    <span v-else-if="isActive" class="flex h-10 w-10 relative flex-center">
      <span
        class="absolute inline-flex inset-0 h-full w-full rounded-full bg-indigo-500 opacity-95"
        :class="{'animate-pulse': isActive}"
      ></span>
      <span class="rounded-full h-8 w-8 bg-indigo-700"></span>
    </span>
    <span v-else class="flex h-10 w-10 flex-shrink-0 rounded-full bg-white relative flex-center">
      <span class="rounded-full h-8 w-8 bg-gray-300"></span>
    </span>
    <p class="text-gray-800 font-semibold mt-4">{{title}}</p>
  </div>
</template>

<script>
export default {
  name: 'JobPollerDot',
  props: {
    activeStatus: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isDone: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isActive() {
      return this.activeStatus === this.status;
    },
  },
};
</script>
