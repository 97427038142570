<template>
  <div class="w-full mx-auto desktop-container pb-24">
    <header class="pb-6 pt-8 border-b">
      <div class="flex justify-between">
        <div class="flex">
          <h2 class="text-2xl mr-2 font-extrabold text-gray-900">{{ title }}</h2>
          <base-button
            v-if="$slots.details || details"
            color="gray"
            :inverted="true"
            size="micro"
            @click="explanationModalOpen = true"
          >
            <icon-information-circle classes="h-5 w-5 text-blue-800"></icon-information-circle>
          </base-button>
        </div>

        <slot name="actions" />
      </div>

      <slot name="description">
        <p class="text-gray-500 mt-1.5" style="max-width: 660px">{{ description }}</p>
      </slot>
    </header>

    <slot></slot>

    <base-modal
      v-model="explanationModalOpen"
      :width="680"
      :is-full-screen="false"
      :close-on-click-outside="true"
      title="Udvidet forklaring"
    >
      <slot name="details">
        <p>{{ details }}</p>
      </slot>

      <base-button
        slot="footer"
        :inverted="true"
        color="gray"
        @click="explanationModalOpen = false"
      >Luk</base-button>
    </base-modal>
  </div>
</template>

<script>
export default {
  name: 'SecondaryNavBarContent',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    details: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      explanationModalOpen: false,
    };
  },
};
</script>
