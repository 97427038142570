<template>
  <div class="flex flex-col h-full">
    <nav aria-label="Progress">
      <ol class="overflow-hidden">
        <planning-validation-step
          v-for="(step, index) in steps"
          :key="step.title"
          :title="step.title"
          :subtitle="step.subtitle"
          :route="step.route"
          :status="step.status"
          :is-last="index === steps.length - 1"
        ></planning-validation-step>
      </ol>
    </nav>
  </div>
</template>

<script>
import PlannedInstanceService from '@/services/plannedInstance.service';

import PlanningValidationStep from '@/components/PlanningValidationStep.vue';
import VALIDATION_STATE from '@/util/validationState';

export default {
  name: 'ExistingRoutesValidationArea',
  components: {
    PlanningValidationStep,
  },
  props: {
    extraSteps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      VALIDATION_STATE,
      geocoding: {
        title: 'Geokodning',
        subtitle: 'Opgaver geokodet',
        status: VALIDATION_STATE.PENDING,
      },
      frequencies: {
        title: 'Frekvenskodning',
        subtitle: 'Opgaver med frekvens',
        status: VALIDATION_STATE.PENDING,
      },
      vehicles: {
        title: 'Køretøjer',
        subtitle: 'Aktive køretøjer',
        status: VALIDATION_STATE.PENDING,
      },
    };
  },
  computed: {
    steps() {
      return [this.geocoding, this.vehicles, this.frequencies, ...this.extraSteps];
    },
    isValid() {
      return this.steps.every((step) => step.status === VALIDATION_STATE.SUCCESS);
    },
  },
  watch: {
    isValid(newIsValid) {
      this.$emit('on-is-valid-change', newIsValid);
    },
  },
  async mounted() {
    try {
      const { data } = await PlannedInstanceService.buildValidation();

      const { geo_coded, frequency_coded, vehicles } = data;

      this.geocoding = {
        ...this.geocoding,
        subtitle: `Opgaver geokodet ${geo_coded.count}/${geo_coded.total}`,
        status: geo_coded.valid ? VALIDATION_STATE.SUCCESS : VALIDATION_STATE.ERROR,
        route: 'geocode',
      };

      this.frequencies = {
        ...this.frequencies,
        subtitle: `Opgaver med frekvens ${frequency_coded.count}/${frequency_coded.total}`,
        status: frequency_coded.valid ? VALIDATION_STATE.SUCCESS : VALIDATION_STATE.ERROR,
        route: 'frequencies',
      };

      this.vehicles = {
        ...this.vehicles,
        subtitle: `Aktive køretøjer ${vehicles.count}/${vehicles.total}`,
        status: vehicles.valid ? VALIDATION_STATE.SUCCESS : VALIDATION_STATE.ERROR,
        route: 'vehicles',
      };
    } catch (error) {
      this.$unhandledError(error);
    }
  },
};
</script>
