<template>
  <ol class="border border-gray-200 rounded-md flex divide-y-0 mb-4 bg-white">
    <li class="relative flex-1 flex">
      <div class="group flex items-center w-full">
        <span class="px-6 py-4 flex items-center text-sm font-medium">
          <span
            v-if="value !== DEFAULT_VALUE"
            class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full"
          >
            <icon-check class="w-6 h-6 text-white"></icon-check>
          </span>
          <span
            v-else
            class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full"
          >
            <span class="w-6 h-6 text-gray-900 font-semibold flex-center">?</span>
          </span>
          <span class="ml-4 text-sm font-medium text-gray-900">
            <slot name="title">{{title}}</slot>
          </span>
        </span>
      </div>

      <!-- Arrow separator for lg screens and up -->
      <div class="block absolute top-0 right-0 h-full w-5" aria-hidden="true">
        <svg
          class="h-full w-full text-gray-300"
          viewBox="0 0 22 80"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 -2L20 40L0 82"
            vector-effect="non-scaling-stroke"
            stroke="currentcolor"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </li>

    <li class="relative flex-1 flex">
      <div class="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
        <span class="ml-4 text-sm font-medium text-indigo-600">
          <base-select-multi
            v-model="model"
            :clearable="true"
            :placeholder="placeholder"
            :options="options"
            :max="max"
            :value-label-transformer="valueLabelTransformer"
          ></base-select-multi>
        </span>
        <slot name="valueLabel"></slot>
      </div>
    </li>
  </ol>
</template>

<script>
const DEFAULT_VALUE = 0;

export default {
  name: 'MappingLineMultiSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    useValue: {
      type: Boolean,
      description: 'Whether to use a provided value from a parent component or use an internal',
    },
    value: {
      type: [String, Number],
      default: DEFAULT_VALUE,
    },
    valueLabelTransformer: {
      type: Function,
      description: 'Will be passed a long to BaseMultiSelect',
      default: null,
    },
    max: {
      type: Number,
      default: Number.MAX_VALUE,
      description: 'Will be passed a long to BaseMultiSelect',
    },
  },
  data() {
    return {
      DEFAULT_VALUE,
      innerValue: [],
    };
  },
  computed: {
    model: {
      get() {
        if (this.useValue) {
          if (!this.value) return [];
          return this.value;
        }
        return this.innerValue;
      },
      set(val) {
        this.innerValue = val;
        this.$emit('selected', {
          label: this.title,
          value: val,
        });
      },
    },
  },
};
</script>
