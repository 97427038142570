const translations = {
  NONE: 'Ingen',
  MANUAL: 'Manuel',
  EXISTING: 'Eksisterende',
  ALGORITHM: 'Algoritme',
  MIGRATED: 'Rykket under import',
  SCRATCH: 'Fra bunden',
};

const translate = (value) => {
  if (!value) return translations.NONE;

  return translations[value];
};
export default translate;
