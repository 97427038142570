const translations = {
  ODD: 'Ulige',
  EVEN: 'Lige',
};

const translate = (value) => {
  if (!value) return '';

  return translations[value];
};
export default translate;
