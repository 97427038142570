<template>
  <div class="w-full mx-auto desktop-container pb-24">
    <header class="pb-6 pt-8 border-b">
      <div class="flex items-baseline">
        <router-link :to="{name: 'plan' }">
          <h2 class="text-2xl font-extrabold text-gray-900">Rutebygning</h2>
        </router-link>
        <span
          v-if="routerTitle"
          class="text-lg font-bold text-gray-600 ml-2 lowercase"
        >{{routerTitle}}</span>
      </div>

      <base-alert
        v-if="hasUnplannedTasks"
        type="warning"
        title="Du har uplanlagte opgaver"
        class="mt-4"
      ></base-alert>
    </header>

    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BuildRoutesView',
  computed: {
    ...mapGetters('departments', ['isInTransitionPeriod']),
    ...mapGetters('status', ['hasPlanned', 'hasUnplannedTasks']),
    routerTitle() {
      return this.$route.meta?.title;
    },
  },
  mounted() {
    if (this.isInTransitionPeriod) {
      this.$router.push({ name: 'transition-period-details' });
      return;
    }

    if (this.hasPlanned && !this.hasUnplannedTasks) {
      this.$router.push({ name: 'all-planned' });
    }
  },
};
</script>

<style>

</style>
