<template>
  <div class="w-full pb-24">
    <div class="pb-6 pt-2">
      <p
        class="text-gray-500 mt-1.5"
        style="max-width: 660px"
      >Her kan du bygge eksisterende ruter. For at bygge ruterne, skal du hjælpe med at kortlægge noget af den data, som findes i det rå data.</p>
    </div>

    <image-container
      v-if="!hasUnplannedTasks"
      asset-name="success"
      title="Du har ingen uplanlagte opgaver"
    ></image-container>

    <div v-else class="flex-1 min-w-0 xl:flex mt-4">
      <!-- Validation sidebar -->
      <div class="flex-shrink-0 w-72 mr-12">
        <existing-routes-validation-area
          class="mt-4"
          :extra-steps="extraValidationSteps"
          @on-is-valid-change="isEveryStepValidated = $event"
        ></existing-routes-validation-area>
      </div>

      <job-poller
        v-if="buildExistingRoutesJobId"
        title="Bygger eksisterende ruter"
        description="Dette kan tage nogle minutter, hvis der er mange ruter"
        :job-id="buildExistingRoutesJobId"
        :type="jobTypes.BUILD_EXISTING_ROUTES"
        @result="onBuildExistingRoutesResult"
      ></job-poller>
      <div v-else class="rounded-md min-w-0 flex-1">
        <div
          class="pl-4 pr-6 pt-4 pb-4 border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0"
        >
          <div v-if="isEveryStepValidated">
            <image-container asset-name="building_blocks">
              <base-button
                color="indigo"
                class="mt-6"
                :disabled="buildExistingRoutesJobId"
                @click="onBuildRoutesSubmit"
              >Byg gamle ruter</base-button>
            </image-container>

            <div v-if="buildRoutesError">
              <image-container
                asset-name="error"
                title="Der skete en valideringsfejl"
                :subtitle="buildRoutesError.error"
              ></image-container>

              <ul>
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-2">
                    <dt class="text-sm font-medium text-gray-500">Opgaver med manglende tømmedage</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span
                        class="text-gray-500 font-bold"
                      >{{buildRoutesError.missing_disposal_day_mappings}}</span>
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-2">
                    <dt class="text-sm font-medium text-gray-500">Opgaver med manglende frekvenser</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span
                        class="text-gray-500 font-bold"
                      >{{buildRoutesError.missing_frequency_mappings}}</span>
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-2">
                    <dt class="text-sm font-medium text-gray-500">Opgaver med manglende geokodninger</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span class="text-gray-500 font-bold">{{buildRoutesError.non_geocoded}}</span>
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-2">
                    <dt class="text-sm font-medium text-gray-500">Opgaver med manglende køretøjer</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span
                        class="text-gray-500 font-bold"
                      >{{buildRoutesError.missing_vehicle_mappings}}</span>
                    </dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-2">
                    <dt class="text-sm font-medium text-gray-500">Opgaver med manglende uge</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span
                        class="text-gray-500 font-bold"
                      >{{buildRoutesError.missing_week_mappings}}</span>
                    </dd>
                  </div>
                </dl>
              </ul>
            </div>
          </div>

          <div v-else>
            <template v-if="!disposalDaysHasMapped">
              <div v-if="dinstinctTaskDisposalDays.length || disposalDaysLoading">
                <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">Tømmedage</h3>
                <base-alert
                  type="info"
                  class="mb-4"
                  title="Hvorfor skal vi vide hvilken tømmedag det er?"
                  :dismissable="true"
                >For at kunne bygge eksisterende ruter, skal vi vide hvilken dag den lå på. Hvis opgaven havde flere tømninger per uge, skal vi vide hvilke dage.</base-alert>
                <ul v-loading="disposalDaysLoading">
                  <li v-for="(disposalDay, index) in dinstinctTaskDisposalDays" :key="index">
                    <mapping-line-multi-select
                      v-if="disposalDay.interval_occurrences > 1"
                      :options="disposalDayOptions"
                      :title="disposalDay.disposal_day"
                      placeholder="Vælg tømmedage"
                      :max="disposalDay.interval_occurrences"
                      :value-label-transformer="translateDisposalDays"
                      @selected="onDisposalDayMapping({ disposal_day: disposalDay.disposal_day, interval_occurrences: disposalDay.interval_occurrences, value: $event.value })"
                    >
                      <div slot="title">
                        <span class="block">{{disposalDay.disposal_day}}</span>
                        <span class="block font-normal">Frekvens {{disposalDay.frequency}}</span>
                      </div>

                      <div
                        slot="valueLabel"
                        :class="disposalDay.interval_occurrences > 1 ? 'text-red-700 ml-4' : 'text-blue-700 ml-4'"
                      >{{disposalDay.interval_occurrences}} værdier*</div>
                    </mapping-line-multi-select>
                    <mapping-line
                      v-else
                      :options="disposalDayOptions"
                      :title="disposalDay.disposal_day"
                      placeholder="Vælg tømmedag"
                      @selected="onDisposalDayMapping({ disposal_day: disposalDay.disposal_day, interval_occurrences: 1, value: $event.value })"
                    >
                      <div slot="title">
                        <span class="block">{{disposalDay.disposal_day}}</span>
                        <span class="block font-normal">Frekvens {{disposalDay.frequency}}</span>
                      </div>

                      <div
                        slot="valueLabel"
                        :class="disposalDay.interval_occurrences > 1 ? 'text-red-700 ml-4' : 'text-blue-800 ml-4'"
                      >{{disposalDay.interval_occurrences}} værdi*</div>
                    </mapping-line>
                  </li>
                </ul>

                <div class="flex justify-end mt-4">
                  <base-button
                    :disabled="disposalDaySubmitDisabled"
                    color="indigo"
                    @click="onDisposalDaySubmit"
                  >Sæt gamle tømmedage på opgaver</base-button>
                </div>
              </div>
              <image-container
                v-else
                asset-name="empty"
                class="-mt-16"
                title="Der blev ikke indlæst nogle gamle tømmedage under import."
                subtitle="Du har muligvis miskonfiguret feltet 'Tømmedag'"
              ></image-container>
            </template>

            <template v-if="!plannedWeekhasMapped">
              <div v-if="plannedWeekNos.length || plannedWeekLoading">
                <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">Uge</h3>
                <base-alert
                  type="info"
                  class="mb-4"
                  title="Hvorfor skal vi vide hvilken uge en opgave starter i?"
                  :dismissable="true"
                >
                  Vi kan ikke gætte hvilken uge en frekvens blev udført i.
                  <br />
                  <br />En frekvens der hedder hver 4. uge kan have værdierne 1, 2, 3 og 4.
                  <br />
                  <br />I planlægningen antages det at Uge 1 er ulige og Uge 2 er lige.
                  <br />
                  <br />Bemærk at frekvenser der løses hver uge skal have værdien "Uge 1"
                </base-alert>
                <ul>
                  <li v-for="plannedWeekNo in plannedWeekNos" :key="plannedWeekNo.title">
                    <mapping-line
                      :options="plannedWeekNo.options"
                      :title="plannedWeekNo.title"
                      placeholder="Vælg uge"
                      @selected="onPlannedWeekMapping"
                    ></mapping-line>
                  </li>
                </ul>

                <div class="flex justify-end mt-4">
                  <base-button
                    color="indigo"
                    :disabled="plannedWeekSubmitDisabled"
                    @click="onPlannedWeekSubmit"
                  >Sæt gamle uger på opgaver</base-button>
                </div>
              </div>

              <image-container
                v-else
                class="border-t"
                asset-name="empty"
                title="Der blev ikke indlæst nogle uger under import"
                subtitle="Du har muligvis miskonfiguret feltet 'Startuge'"
              ></image-container>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExistingRoutesValidationArea from '@/components/build-routes/ExistingRoutesValidationArea.vue';

import ImageContainer from '@/components/ImageContainer.vue';
import TaskService from '@/services/tasks.service';
import MappingLine from '@/components/MappingLine.vue';
import MappingLineMultiSelect from '@/components/MappingLineMultiSelect.vue';

import disposalDayOptions from '@/tables/combo/disposal_day_options';
import { mapGetters, mapActions } from 'vuex';

import { BUILD_EXISTING_ROUTES, CHECK_PLANNED_STATUS } from '@/store/actions.type';
import * as jobTypes from '@/util/jobTypes';
import JobPoller from '@/components/JobPoller.vue';
import VALIDATION_STATE from '@/util/validationState';

import daLocale from '@/locales/da-DK';

export default {
  name: 'PlanExistingRoutesView',
  components: {
    ImageContainer,
    MappingLine,
    MappingLineMultiSelect,
    ExistingRoutesValidationArea,
    JobPoller,
  },
  data() {
    return {
      buildRoutesError: null,
      disposalDayOptions,
      disposalDaysLoading: false,
      disposalDaysHasMapped: false,
      disposalDayMap: {},
      dinstinctTaskDisposalDays: [],
      plannedWeekNos: [],
      plannedWeekLoading: false,
      plannedWeekhasMapped: false,
      plannedWeekMap: {},
      isEveryStepValidated: false,
      jobTypes,
    };
  },
  computed: {
    ...mapGetters('jobs', ['buildExistingRoutesJobId']),
    ...mapGetters('status', ['hasPlanned', 'hasUnplannedTasks']),
    ...mapGetters('departments', ['activeWorkspace']),
    extraValidationSteps() {
      return [
        {
          title: 'Gamle tømmedage',
          subtitle: 'Skal kortlægges',
          status: this.disposalDayStatus,
        },
        {
          title: 'Gamle uger',
          subtitle: 'Skal kortlægges',
          status: this.plannedWeekStatus,
        },
      ];
    },
    disposalDayStatus() {
      if (this.disposalDaysHasMapped || this.buildExistingRoutesJobId) return VALIDATION_STATE.SUCCESS;
      return this.disposalDaysLoading ? VALIDATION_STATE.PENDING : VALIDATION_STATE.ERROR;
    },
    plannedWeekStatus() {
      if (this.plannedWeekhasMapped || this.buildExistingRoutesJobId) return VALIDATION_STATE.SUCCESS;
      return this.plannedWeekLoading ? VALIDATION_STATE.PENDING : VALIDATION_STATE.ERROR;
    },
    disposalDaySubmitDisabled() {
      if (this.disposalDaysLoading) return true;

      // if they are not even the same size we can already tell it's not valid
      if (Object.keys(this.disposalDayMap).length !== this.dinstinctTaskDisposalDays.length) {
        return true;
      }

      // if they are same size we need to additionally validate that for each the interval ocurrences == mapped values
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.disposalDayMap)) {
        const [disposal_day, interval_occurrences] = key.split('#');
        const theDisposalDayObj = this.dinstinctTaskDisposalDays.find(
          (x) => x.disposal_day === disposal_day && x.interval_occurrences === parseInt(interval_occurrences, 10),
        );

        const theValue = Array.isArray(value) ? value : [value];
        if (theDisposalDayObj.interval_occurrences !== theValue.length) {
          return true;
        }
      }

      // if we reached here it's valid
      return false;
    },
    plannedWeekSubmitDisabled() {
      if (this.plannedWeekLoading) return true;
      return Object.keys(this.plannedWeekMap).length !== this.plannedWeekNos.length;
    },
  },
  async mounted() {
    await this.getDistinctTaskDisposalDays();
    await this.getPlannedWeeks();
  },
  methods: {
    ...mapActions('planning', {
      buildExistingRoutes: BUILD_EXISTING_ROUTES,
    }),
    ...mapActions('status', {
      checkPlannedStatus: CHECK_PLANNED_STATUS,
    }),
    onBuildExistingRoutesResult() {
      this.checkPlannedStatus();
      this.$router.push({ name: 'map' });
    },
    translateDisposalDays(values) {
      return daLocale.disposalDayList(values);
    },
    async getDistinctTaskDisposalDays() {
      this.disposalDaysLoading = true;
      try {
        const { data } = await TaskService.getDistinctTaskDisposalDays();

        this.dinstinctTaskDisposalDays = data;
      } catch (error) {
        this.$unhandledError(error);
      } finally {
        this.disposalDaysLoading = false;
      }
    },
    async getPlannedWeeks() {
      this.plannedWeekLoading = true;
      try {
        const { data } = await TaskService.getDistinctTaskPlannedWeekNumbers();
        this.plannedWeekNos = data;
      } catch (error) {
        this.$unhandledError(error);
      } finally {
        this.plannedWeekLoading = false;
      }
    },
    async onDisposalDaySubmit() {
      try {
        this.disposalDaysLoading = true;

        // we need to convert single fields to an array
        const formattedDisposalDayMap = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.disposalDayMap)) {
          const [disposal_day, interval_occurrences] = key.split('#'); // Split the key to extract disposal_day and interval_occurrences
          const disposalDayArray = Array.isArray(value) ? value : [value];
          formattedDisposalDayMap.push({
            disposal_day_key: disposal_day,
            interval_occurrences: parseInt(interval_occurrences, 10),
            disposal_day_value: disposalDayArray,
          });
        }

        await TaskService.saveOldDisposalDayMappings({ mappings: formattedDisposalDayMap });
        this.$ntf.success('De gamle tømmedage er nu kortlagt');
        this.disposalDaysHasMapped = true;
      } catch (error) {
        this.$unhandledError(error);
      } finally {
        this.disposalDaysLoading = false;
      }
    },
    async onPlannedWeekSubmit() {
      try {
        this.plannedWeekLoading = true;
        const mappings = this.plannedWeekMap;
        mappings[''] = mappings.Tom;
        delete mappings.Tom;
        await TaskService.saveOldPlannedWeekNumberMappings({ mappings });
        this.$ntf.success('De gamle uger er nu kortlagt');
        this.plannedWeekhasMapped = true;
      } catch (error) {
        this.$unhandledError(error);
      } finally {
        this.plannedWeekLoading = false;
      }
    },
    async onBuildRoutesSubmit() {
      try {
        this.$ntf.info('Bygger gamle ruter');
        await this.buildExistingRoutes();
      } catch (error) {
        if (error.status === 500) {
          this.$unhandledError(error);
        } else {
          this.buildRoutesError = error.data;
        }
      }
    },
    onDisposalDayMapping({ disposal_day, interval_occurrences, value }) {
      // Construct a unique label combining disposal_day and interval_occurrences
      const combinedLabel = `${disposal_day}#${interval_occurrences}`;

      // Remove any existing mapping for this combination
      delete this.disposalDayMap[combinedLabel];

      // Only add the value if it exists
      if (value) {
        this.disposalDayMap[combinedLabel] = value;
      }

      // Trigger Vue's reactivity system to detect the change
      // https://vuejs.org/v2/guide/reactivity.html
      this.disposalDayMap = { ...this.disposalDayMap };
    },
    onPlannedWeekMapping({ label, value }) {
      // ensure it is possible to change values
      delete this.plannedWeekMap[label];

      if (value) {
        this.plannedWeekMap[label] = value;
      }

      // trick vue into triggering a reactivity change
      // https://vuejs.org/v2/guide/reactivity.html
      this.plannedWeekMap = { ...this.plannedWeekMap };
    },
  },
};
</script>
