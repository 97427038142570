const translations = {
  NONE: 'Ingen',
  CITY: 'By',
  SUMMER: 'Sommerhus',
  LAND: 'Land',
};

const translate = (value) => {
  if (!value) return '';

  const isInTranslations = value in translations;

  if (!isInTranslations) return value;

  return translations[value];
};
export default translate;
